// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-aspirations-celebrate-every-person-tsx": () => import("./../../../src/pages/aspirations/celebrate-every-person.tsx" /* webpackChunkName: "component---src-pages-aspirations-celebrate-every-person-tsx" */),
  "component---src-pages-aspirations-climate-change-tsx": () => import("./../../../src/pages/aspirations/climate-change.tsx" /* webpackChunkName: "component---src-pages-aspirations-climate-change-tsx" */),
  "component---src-pages-aspirations-education-tsx": () => import("./../../../src/pages/aspirations/education.tsx" /* webpackChunkName: "component---src-pages-aspirations-education-tsx" */),
  "component---src-pages-aspirations-future-ready-tsx": () => import("./../../../src/pages/aspirations/future-ready.tsx" /* webpackChunkName: "component---src-pages-aspirations-future-ready-tsx" */),
  "component---src-pages-aspirations-truth-reconciliation-tsx": () => import("./../../../src/pages/aspirations/truth-reconciliation.tsx" /* webpackChunkName: "component---src-pages-aspirations-truth-reconciliation-tsx" */),
  "component---src-pages-aspirations-workforce-development-tsx": () => import("./../../../src/pages/aspirations/workforce-development.tsx" /* webpackChunkName: "component---src-pages-aspirations-workforce-development-tsx" */),
  "component---src-pages-foundations-tsx": () => import("./../../../src/pages/foundations.tsx" /* webpackChunkName: "component---src-pages-foundations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intro-tsx": () => import("./../../../src/pages/intro.tsx" /* webpackChunkName: "component---src-pages-intro-tsx" */),
  "component---src-pages-vision-tsx": () => import("./../../../src/pages/vision.tsx" /* webpackChunkName: "component---src-pages-vision-tsx" */)
}

